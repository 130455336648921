import React from "react"
import styled from "styled-components"
import nap from "../../static/assets/map/guj.svg"
import india from "../../static/assets/map/india.svg"
import BaseLayout from "../components/BaseLayout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SchoolSlider from "../components/schools/SchoolSlider"
import Container from "../components/structural/Container"
import { FormattedMessage } from "react-intl"

const Wrapper = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    border-radius: 0px;
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
    padding-top: 16px;
  }

  margin-bottom: 48px;

  .bold {
    font-weight: 700;
  }

  img {
    max-width: 420px;
    margin: auto;
  }
`

const Area = styled.div`
  display: flex;
`

const Slider = styled(SchoolSlider)`
  @media only screen and (min-width: 768px) {
    flex-wrap: wrap !important;
    justify-content: center;

    > div:first-child {
      padding-left: 0px;
    }

    > div:last-child {
      padding-right: 0px;
    }
  }
`

export default function MapPage() {
  return (
    <>
      <BaseLayout>
        <Header sub fixed border></Header>
        <Container heading="schools.heading">
          <Wrapper className="px-8">
            <Area className="whitebg px-2 flex-column">
              <div className="text-center py-4">
                <FormattedMessage id="schools.p.1">
                  {(txt) => <div dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
              <img
                id="india"
                src={india}
                alt="india map"
                className="mt-4"
              ></img>
              <div className="text-center py-4">
                <FormattedMessage id="schools.p.2">
                  {(txt) => <div dangerouslySetInnerHTML={{ __html: txt }} />}
                </FormattedMessage>
              </div>
            </Area>
            <Area className="flex-column px-2 py-12">
              <img id="nap" src={nap} alt="gujarat map"></img>
            </Area>
            <Area className="py-12">
              <Slider></Slider>
            </Area>
          </Wrapper>
        </Container>
        <Footer></Footer>
      </BaseLayout>
    </>
  )
}
