import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SchoolCard from "./SchoolCard"
import { FormattedMessage } from "react-intl"

const Slider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* height: calc(100vh - 80px); */
  /* align-self: center; */
  padding-bottom: 8px;

  > div:first-child {
    padding-left: 16px;
  }

  > div:last-child {
    padding-right: 16px;
  }
`

const schoolList = [
  {
    id: 0,
    name: "Jol Primary School",
  },
  {
    id: 1,
    name: "Municipal School 33",
  },
  {
    id: 2,
    name: "G.J. Patel Girls School",
  },
  {
    id: 3,
    name: "MS Mistry Primary School",
  },
  {
    id: 4,
    name: "Bakrol Primary Girls",
  },
  {
    id: 5,
    name: "Bhatiel Primary School",
  },
  {
    id: 6,
    name: "Sinhol Primary and Secondary",
  },
  {
    id: 7,
    name: "Pritam School",
  },
  {
    id: 8,
    name: "Kosindra Secondary",
  },
  {
    id: 9,
    name: "Municipal School 15",
  },
]

export default function SchoolSlider({ className }) {
  const data = useStaticQuery(graphql`
    query {
      schoolimg: allFile(
        filter: { relativeDirectory: { eq: "schools" } }
        sort: { order: ASC, fields: absolutePath }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Slider className={className}>
      {schoolList.map((school, index) => {
        return (
          <SchoolCard
            key={index}
            img={data.schoolimg.edges[school.id].node.childImageSharp.fluid}
            h={school.name}
            p={<FormattedMessage id={"schools.texts." + school.id} />}
          ></SchoolCard>
        )
      })}
    </Slider>
  )
}
