import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Card = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 16px 8px 0px 8px;
  position: relative;
  z-index: 2;

  height: 80vh;
  max-height: 400px;
  width: 250px;
  overflow: hidden;
  flex: 0 0 auto;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
`

const CardHeader = styled.div`
  height: 160px;
  overflow: hidden;
`

const CardContent = styled.div`
  padding: 8px 8px 8px 8px;

  span {
    font-size: 20px;
  }

  p {
    margin: 0px;
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
  }

  display: flex;
  flex-direction: column;
`

export default function SchoolCard({ img, h, p, children }) {
  return (
    <>
      <div>
        <Card id={"school"}>
          <CardHeader>
            <Img fluid={img} />
          </CardHeader>
          <CardContent>
            <span>{h}</span>
            <p>{p}</p>
            {children}
          </CardContent>
        </Card>
      </div>
    </>
  )
}
